//开票审核列表
export const reviewCols = [
  {
    prop: 'invoiceCode',
    label: '发票ID',
    minWidth: '200'
  },
  {
    prop: 'applyUserNick',
    label: '分销商',
    minWidth: '200'
  },
  {
    prop: 'type',
    label: '发票类型',
    minWidth: '200',
    slotName: 'typeSlot'
  },
  {
    prop: 'select',
    label: '发票选项',
    minWidth: '200',
    slotName: 'selectSlot'
  },
  {
    prop: 'invoiceTitle',
    label: '发票抬头',
    minWidth: '200'
  },
  {
    prop: 'invoiceAmount',
    label: '开票金额',
    minWidth: '200',
    slotName: 'moneySlot'
  },
  {
    prop: 'companyTaxNo',
    label: '税号',
    minWidth: '200'
  },
  {
    label: '物流信息',
    minWidth: '200',
    slotName: 'expressSlot'
  },
  {
    prop: 'consigneeEmail',
    label: '收件邮箱',
    minWidth: '200'
  },
  {
    prop: 'applyTime',
    label: '申请时间',
    minWidth: '200',
    slotName: 'applyTimeSlot',
    sortable: true
  },
  {
    prop: 'invoiceStatus',
    label: '状态',
    minWidth: '200',
    slotName: 'invoiceStatusSlot'
  }
]

//审核列表
export const cols = [
  {
    prop: 'productCnName',
    label: '产品名称',
    minWidth: '150'
  },
  {
    prop: 'orderCode',
    label: '所属订单号',
    minWidth: '150'
  },
  {
    prop: 'productStructName',
    label: '款式',
    minWidth: '150'
  },
  {
    prop: 'productCount',
    label: '数量',
    minWidth: '150'
  },
  {
    prop: 'realUnitPrice',
    label: '单价',
    minWidth: '150',
    slotName: 'priceSlot'
  },
  {
    prop: 'totalPrice',
    label: '总金额',
    minWidth: '150',
    slotName: 'moneySlot'
  }
]
